.social {
  width: 320px;
  height: 50px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0 auto;
}
.buttonSocial {
  width: 150px;
  height: 50px;
  border-radius: 18px;
  border: solid 2px #ededed;
  background-color: #ffffff;
  &:hover {
    background-color: #ededed94;
  }
}
.iconSocial {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 50px !important;

  svg {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    min-height: 28px !important;
    max-height: 28px !important;
  }
  .iconGoogleSocial {
    width: 28px !important;
    height: 28px !important;
    width: 100% !important;
    margin-top: 8px;
  }
}
