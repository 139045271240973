.profileInformationFormWrapper {
  padding: 15px;
}
.formInHomepage {
  box-shadow: 6px 6px 0 0 #e4f0ff;
  border: solid 1px #0872ca;
  padding: 10px;
  min-height: 180px;
  .formWrapper {
    max-width: 100%;
  }
  .vnrb-comp-profile-update-info { 
    padding-bottom: 0;
    margin-top: 0;
  }
  @media screen and (max-width: 576px) {
    margin: 10px;
  }
  // .buttonLogin {
  //   margin: 0 auto !important;
  // }
}
.profileInfoRow {
  @media screen and (max-width: 576px) {
    .vnrb-comp-profile-update-info {
      margin-top: 0;
    }
  }
}
.tooltipVideo {
  a {
    color: #FFF;
    text-decoration: underline;
  }
  ul {
    margin-bottom: 0;
  }
  :global .ant-tooltip-inner {
    padding: 10px 15px;
  }
}
.vnrb-comp-profile-update-info {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-items: flex-start;
  padding-bottom: 15px;
  flex-direction: column;
  a {
    text-decoration: underline !important;
    color: #333;
  }
  .profile-title {
      min-height: 160px;
    position: relative;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    width: 100%;
    color: #fff;
    background-image: linear-gradient(90deg, #0872ca 100%) !important;
    padding: 20px 10px 20px 10px;
    max-width: 820px;
    @media screen and (max-width: 576px) {
      text-align: justify;
    }
  }

  .profile-email {
    padding: 10px 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
  }

  .profile-form-col {
    width: 50%;
    button {
      width: 100%;
      border-radius: 2px;
      background-image: linear-gradient(90deg, #0872ca 100%) !important;
      border: none;

      &:hover,
      &:focus,
      &:active {
        background-image: linear-gradient(90deg, #0971b7 30%, #3c315095 100%) !important;
        border: none;
      }
    }
  }
  :global .ant-form-item {
    margin-bottom: 2px;
  }

  .inputAddonBefore {
    width: fit-content;
    height: 20px;
    font-family: Nunito-Regular, Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    text-align: left;
    color: #999999;
    position: absolute;
    top: 7px;
    left: 15px;
    z-index: 1;
  }

  .inputForm {
    padding: 7px;
    border-radius: 4px;
    font-family: Nunito, Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 32px;
    color: #333333 !important;
    border: solid 1px #ededed !important;
    background-color: #ffffff !important;
    height: unset;    
    @media screen and (min-width: 425px) {
      min-width: 250px;
    }
    &:hover {
      box-shadow: 6px 6px 0 0 #e4f0ff !important;
      border: solid 1px #0872ca !important;
    }
    &:focus {
      border: solid 1px #0872ca !important;
      box-shadow: unset !important;
    }
    &::placeholder {
      color: #999999 !important;
    }
    :disabled {
      background-color: #ededed !important;
    }
  }

  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border: unset;
  }

  :global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
    padding: 0;
  }

  :global(.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector) {
    border-color: unset;
    box-shadow: unset;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: #0872ca;
    border-color: #0872ca;
  }

  :global .ant-form-item-explain-error {
    padding: 5px 8px 0;
    font-size: 13px;
  }

  .buttonLogin, .buttonRegister {
    width: fit-content;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: unset;
    background-color: #0872ca;
    cursor: pointer;
    
    &:hover {
      background-color: #03bac6;
      .textLogin {
        color: #ffffff;
      }
    }
  }
  .buttonLogin {
    margin: 15px auto 0;
  }
  .buttonRegister {
    margin: 0 auto;
  }
  .textLogin {
    font-family: Nunito, Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    padding: 2px 7px;
  }

  @media (max-width: 700px) {
    .profile-form {
      .profile-form-col {
        width: 80%;
      }
    }
  }

  @media (max-width: 1000px) {
    .profile-form {
      .profile-form-col {
        width: 65%;
      }
    }
  }

  @media screen and (max-width: 460px) {
    .ant-form {
      padding: 0 10px 0 10px;
    }
  }
}
.avatarWrapper {
  position: relative;
}
.uploadAvatarButton {
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: center;
  padding: 5px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  bottom: 3px;
  right: 3px;
  svg {
    position: absolute;
    top: 0;
    left: 0;
    &:hover {
      transform: scale(1.05);
    }
    transition: all 0.3s;
  }
}
.formWrapper {
  width: 100%;
  max-width: 800px;
  padding: 15px 5px 0 5px;
}
.userIntent {
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media screen and (min-width: 768px) {
    margin-top: -3px;
  }
}
.imageHeader {
  @media screen and (max-width: 576px) {
    max-width: 250px;
    margin: 0 auto;
  }
}
.optionName {
  padding-left: 5px;
  cursor: pointer;
  color: #333333;
  &:hover {
    a {
      color: #0872ca;
    }
  }
  span {
    text-decoration: underline;
  }
  @media screen and (max-width: 576px) {
    font-size: 13px;
  }
}
.guildText {
  font-family: Nunito;
  text-align: center;
  color: #0872ca;
  padding-bottom: 7px;
  width: 100%;
  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
}

.arrowFlipWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(51, 51, 51, 0.1);
  background: #FFFFFF;
  border-radius: 16px;
  width: 100%;
  height: 100%;
}
.inputFormUnitValue {
  padding: 8px;
  font-family: Nunito, Arial, Helvetica, sans-serif;
  color: #333333 !important;
  background: #FFFFFF !important;
  border: solid 1px #ededed;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  &:hover {
    box-shadow: 6px 6px 0 0 #e4f0ff !important;
    border: solid 1px #0872ca !important;
  }
  &:focus {
    border: solid 1px #0872ca !important;
    box-shadow: unset !important;
  }
  &::placeholder {
    color: #999999 !important;
  }
  :disabled {
    background-color: #ededed !important;
  }
  :global .ant-input-number-handler-wrap {
    display: none;
  }
  :global .ant-input-number-input {
    font-size: 14px;
    line-height: 32px;
    padding: 0;
  }
}
.selectRebatePerUnit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.selectRebatePerUnitLandingPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 576px) {
    justify-content: unset;
  }
}
.dropDownMenu {
  width: 320px !important;
  @media screen and (max-width: 576px) {
    width: 200px !important;
  }
}
.selectUnit {
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100px;
  }
  :global .ant-select-show-arrow {
    border-radius: 4px;
    height: 48px;
    .ant-select-selection-item {
      font-weight: 700;
      font-size: 14px;
      line-height: 32px;
      padding: 7px;
      span {
        display: none;
      }
    }
    
  }
  :global .ant-select-selector {
    height: 100% !important;
    border-radius: 4px !important;
    border: solid 1px #ededed !important;
    &:hover {
      box-shadow: 6px 6px 0 0 #e4f0ff !important;
      border: solid 1px #0872ca !important;
    }
    &:focus {
      border: solid 1px #0872ca !important;
      box-shadow: unset !important;
    }
    &::placeholder {
      color: #999999 !important;
    }
    :disabled {
      background-color: #ededed !important;
    }
  }
  :global .ant-select-arrow  {
    top: calc(50% - 10px);
    width: 32px;
    height: 32px;
  }
  .arrowFlipWrapper {
    border: none !important;
  }
}
.inputFormUnitValue {
  flex: none;
  width: 250px;
  margin-left: 4px;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
.phoneNumberForm {
  width: 100%;
  @media screen and (max-width: 576px) {
    width: calc(100% - 105px);
  }
}
.countryName {
  color: #999999;
  font-size: 12px;
  line-height: 16px;
  padding-left: 5px;
  font-weight: 400;
}

.timerStatus {
  font-family: Nunito;
  font-size: 14px;
  color: grey;
  padding-bottom: 8px;
}
.timerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 8px;
  text-align: center;
  width: 300px;
  margin: 12px auto 0;
}
.timerDiv {
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.timeValue {
  font-size: 24px;
  font-family: Nunito;
  color: #ff7300;
}
.timeUnit {
  font-size: 12px;
  color: #fc943e;
}
.dot {
  font-size: 20px;
  font-family: Nunito;
  color: #ff7300;
  padding-top: 5px;
}
.orderStyle {
  width: 32px;
  height: 32px;
  background-color: #52c41a;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.nonOrderStyle {
  margin: 0 auto;
  text-align: center;
}
.cellContent {
  text-align: center;
  position: relative;
}
.background  {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: round;
  background: url('/images/cv9-01.jpg'), lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.headerText {
  animation: pulsate 1.2s infinite alternate; 
  color: #fff;
  font-size: 18px;
  padding: 0 24px;
  text-shadow:
  0 0 2px #fff,
  0 0 15px #fff,
  0 0 6px #fff,
  0 0 12px #5271ff,
  0 0 18px #5271ff;
  @media screen and (max-width: 576px) {
    font-size: 16px;
    padding: 0;
  }
}
@keyframes pulsate {
    
  100% {

      text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #5271ff,
      0 0 80px #5271ff;
  
  }
  
  0% {

    text-shadow:
    0 0 2px #fff,
    0 0 4px #fff,
    0 0 6px #fff,
    0 0 10px #5271ff,
    0 0 45px #5271ff;
  }
}
.supportInfo {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  svg {
    width: 30px !important;
    display: flex;
    height: 30px !important;
    fill: #2297d0 !important;
  }
  img {
    width: 25px;
  }
}
.offerHeading {
  font-family: Nunito;
  color: #333;
  font-size: 16px;
  line-height: 1.25;
}
