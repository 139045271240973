@font-face {
  font-family: Neue-Regular;
  font-display: swap;
  src: url('/static/fonts/NeueHaasGroteskTextProVN-65Medium.otf');
}
.title {
  color: var(--Secondary-500, #FF8104);
font-family: Neue;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px; /* 140% */
}
.popup-perform-target-reward {
  background-color: #ffffff;
  padding-bottom: 0px !important;
  font-size: 16px;
  top: 50px;
  :global .ant-modal-body {
    padding: 24px 40px 40px;
    max-width: 100%;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    margin: auto;
    .header {
      flex-direction: column;
      &-title,
      &-requirement-text {
        text-align: center;
      }
      &-title {
        color: #0872ca;
        font-size: 16px;
        line-height: 1.5em;
        padding-bottom: 12px;
      }
      &-requirement-text {
        padding: 20px 0;
        color: var(--Neutral-900, #2E2E2E);
text-align: center;

/* Body/Medium/Regular */
font-family: Neue-Regular;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
      }
    }
  }
}
.itemAction {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.rebateRate {
  width: 192px;
  display: flex;
  height: 52px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid rgba(2, 114, 202, 0.2);
  background-color: #fff;
  box-shadow: 0px 4px 0px 0px rgba(8, 114, 202, 0.2);
  gap: 12px;
  font-family: Neue;
  color: #2E2E2E;
  @media screen and (max-width: 768px) {
    width: 50%;
  }
}
.imageWrapper {
  img {
    width: 100%;
    max-width: 300px;
  }
}
.groupVipCol {
  margin: 20px;
}
.groupVip {
  background: #ff7300;
  color: #fff !important;
  font-family: Nunito;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none !important;
  padding: 9px 15px;
  border-radius: 999px;
  border: 2px solid #ff7300;
  transition: all ease 0.5s;
  &:hover {
    // transition: all ease 0.4s;
    background-color: #FFF;
    color: #ff7300 !important;
  }
}
