.flipCard, .flipCardOpen {
  background-color: transparent;
  width: 11px;
  height: 8px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipCardOpen .flipCardInner {
  transform: rotateX(180deg);
}

.flipCardFront, .flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipCardFront {
  background-color: transparent;
  color: black;
}

.flipCardBack {
  background-color: transparent;
  transform: rotateX(180deg);
}
.flipCardBackSvg {
  transform: rotateX(180deg);
}