.vnrLoginForm {
  width: 320px;
  margin: 0 auto !important;
}
.inputForm {
  width: 320px !important;
  height: 60px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 27px 15px 7px !important;
  border-radius: 4px !important;
  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 16px !important;
  line-height: 1.63 !important;
  text-align: left;
  color: #333333 !important;
  border: solid 1px #ededed !important;
  background-color: #ffffff !important;
  &:hover {
    box-shadow: 6px 6px 0 0 #e4f0ff;
    border: solid 1px #0872ca !important;
  }
  &:focus {
    border: solid 1px #0872ca !important;
    box-shadow: unset !important;
  }
  &::placeholder {
    color: #999999 !important;
  }
  :disabled {
    background-color: #ededed !important;
  }
  :first-child {
    display: flex;
    flex-direction: column;
    border: none;
    background-color: unset;
  }
}
.inputAddonBefore {
  width: fit-content;
  height: 20px;
  font-family: Nunito-Regular, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #999999;
  position: absolute;
  top: 7px;
  left: 15px;
  z-index: 2;
}
.formAction {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.buttonLogin {
  width: fit-content;
  height: 50px !important;
  flex-grow: 0;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 25px !important;
  margin-left: 30px;
  border-radius: 16px !important;
  border: unset !important;
  background-color: #0872ca !important;
  cursor: pointer !important;
  &:hover {
    background-color: #03bac6 !important;
    .textLogin {
      color: #ffffff !important;
    }
  }
}
.loginImage {
  position: relative;
  width: 28px;
  height: 28px;
  margin-right: 10px;
  color: #ffffff;
}
.textLogin {
  width: fit-content;
  height: 26px;
  flex-grow: 0;
  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.44;
  text-align: center;
  color: #fff;
}
.buttonForgotPassword {
  width: 115px;
  font-family: Nunito-Regular, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: #333333;
  text-decoration: underline;
  cursor: pointer;
}
.instruction {
  font-family: Nunito-Regular, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  color: #333333;
  margin-bottom: 20px;
}
.requiredMark {
  color: red;
  font-weight: 700;
  padding-right: 3px;
}
.checkbox {
  padding-bottom: 10px;
  :global .ant-checkbox-wrapper {
    align-items: flex-start;
  }
  :global .ant-checkbox-inner {
    width: 28px;
    height: 28px;
    border: 2px solid #999999;
    @media screen and (max-width: 576px) {
      width: 20px;
      height: 20px;
    }
  }
  :global .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #0872ca;
      border-color: #0872ca;
    }
    .ant-checkbox-inner::after {
      top: 48%;
      height: 13.12px;
      width: 8.75px;
      @media screen and (max-width: 576px) {
        width: 6.25px;
        height: 9.28px;
      }
    }
  }
}