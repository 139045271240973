@font-face {
  font-family: Nunito;
  font-display: swap;
  src: url('/static/fonts/Nunito-Bold.ttf');
}
@font-face {
  font-family: Nunito-Regular;
  font-display: swap;
  src: url('/static/fonts/Nunito-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}
html {
  height: 100%;
}
h1 {
  font-size: 22px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}
a {
  text-decoration: none;
}
img {
  border: 0;
  border-spacing: 0;
}
img,
embed {
  vertical-align: middle;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
img,
embed {
  margin: 0;
  padding: 0;
  outline: 0;
}
/* remember to define focus styles! */
:focus {
  outline: 0;
}
ol,
ul {
  list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
  border-color: #e8e8e8;
}
table td {
  border-color: #e8e8e8;
}
caption,
th,
td {
  font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}
blockquote,
q {
  quotes: '' '';
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.wp-caption {
  width: 100% !important;
}

.wp-caption-text {
  text-align: center;
}

.frame_phantrang {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.page-numbers {
  border: 1px solid #ddd;
  padding: 8px;
  margin: 3px;
}

body {
  background-color: #fff;
  margin: 0;
  // font-family: Nunito-Regular !important;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  #__next {
    @media (max-width: 767px) {
      max-width: 100vw;
      font-size: 16px;
      // overflow: hidden;
    }
  }
  #logoBanner {
    @media (max-width: 767px) {
      display: none;
    }
  }
}

body {
  font-family: Nunito-Regular, Arial, Helvetica, sans-serif;
}
.fonts-loaded body {
  font-family: Nunito-Regular, Arial, Helvetica, sans-serif;
}
.fonts-loading body {
  visibility: hidden;
}

.fonts-loaded body,
.fonts-failed body {
  visibility: visible;
}

.ly-forex {
  &__content {
    img {
      width: 100%;
      height: initial;
    }
  }
}

.block-title {
  border-bottom: 3px solid #f5f5f5;
  font-size: 21px;
  margin-bottom: 10px;
  padding-bottom: 3px;
  color: #000;
}

#postContentContainer {
  .vnrb-rich-text-wp-viewer {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}

.btn-xem-ngay {
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 14px;
  background-image: linear-gradient(135deg, #f55555 10%, #fccf31 100%);
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  font-weight: bold;
  padding: 10px 30px;
}

.w-100 {
  width: 100%;
}

.app-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
}